import { useSelector } from "@xstate/react";
import useAuth from "hooks/auth/useAuth.ts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getCompanyId } from "@/blackbox/storage/user.ts";
import { AutocompleteField } from "@/components";
import { useI18n, useSidebar } from "@/hooks";
import useCheckUserGroup from "@/hooks/auth/useCheckUserGroup.ts";
import useUser from "@/hooks/auth/useUser.ts";
import { CompanyDto, companyHttpClient } from "@/services";

const CompanySelect = () => {
  const authActor = useAuth();
  const { organizationId, companyId: selectedCompanyId } = useSelector(
    authActor,
    (snapshot) => snapshot.context,
  );
  const user = useUser();
  const checkUserGroup = useCheckUserGroup();
  const navigate = useNavigate();

  const { t } = useI18n("common");

  const isSidebarOpen = useSidebar((state) => state.isSidebarOpen);
  const showCompanyPrefix = checkUserGroup(["ffsuperadmin", "orgadmin"]);

  const companyWithPrefix = (company: CompanyDto) => {
    if (company.usageType && showCompanyPrefix) {
      const prefix = company.externalCode ? `${company.externalCode} - ` : "";
      return prefix + company.name;
    }

    return company.name;
  };

  const [selectedCompany, setSelectedCompany] = useState<CompanyDto | null>(
    null,
  );

  useEffect(() => {
    if (selectedCompany && selectedCompany.id !== selectedCompanyId) {
      authActor.send({
        type: "assignCompany",
        payload: { companyId: selectedCompany.id },
      });

      const homePage = checkUserGroup(["pm", "artist3d"])
        ? "/sku-list"
        : "/gallery";

      navigate(homePage, { replace: true, state: { reload: Date.now() } });
    }
  }, [selectedCompany]);

  // Unfortunately due to the type of component that was chosen it is
  // not possible to use a state machine in this point
  const fetch = async (term: string) => {
    const { result } = await companyHttpClient.list({
      generic: term ?? undefined,
      partnerId: user.partnerId ?? undefined,
      sortBy: "name.asc",
      organizationId: organizationId ?? user.organizationId,
    });

    if (!selectedCompany) {
      let company = selectedCompanyId
        ? result.find((comp) => comp.id === selectedCompanyId)
        : null;

      // yes, we are going to fetch the company specified in the localStorage,
      // if we do not find it here
      if (
        !company &&
        selectedCompanyId &&
        getCompanyId() === selectedCompanyId
      ) {
        company = await companyHttpClient.get(selectedCompanyId);
      }

      setSelectedCompany(company ?? result[0] ?? null);
    }

    if (selectedCompany && selectedCompany?.organizationId !== organizationId) {
      setSelectedCompany(result[0] ?? null);
    }

    return result;
  };

  return (
    <AutocompleteField
      key={organizationId}
      hidden={!isSidebarOpen}
      label={t("appNavigation.companySelectLabel")}
      inputSize="small"
      loadOptions={fetch}
      isMulti={false}
      formatOptionLabel={companyWithPrefix}
      value={selectedCompany}
      defaultOptions
      cacheOptions
      onChange={(selectedOption: any) => {
        setSelectedCompany(selectedOption);
      }}
      getOptionValue={(option) => option.id}
    />
  );
};

export default CompanySelect;
